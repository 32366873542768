// import smoothscroll from 'smoothscroll-polyfill';
// smoothscroll.polyfill();

class App {
    constructor() {
        this.datas = [];
        this.slide_time = 3000;
        this.transition_time = 1000;
    }

    gnav() {
        const $sub_nav = $('.header ul li[data-class="subnav"], .footer-links ul li[data-class="subnav"]');
        var enterEventType = ((window.ontouchstart !== null) ? 'mouseenter' : 'touchstart');
        var leaveEventType = ((window.ontouchstart !== null) ? 'mouseleave' : 'touchstart');
        $sub_nav.on(enterEventType, function (e) {
            e.preventDefault();
            if (enterEventType == 'touchstart') {
                $(this).parents('ul').find('ul').removeClass('active');
                $(this).find('ul').toggleClass('active');
            } else {
                $(this).find('ul').addClass('active');
            }
        });
        $sub_nav.on(leaveEventType, function (e) {
            e.preventDefault();
            if (leaveEventType == 'touchstart') {
                // $(this).find('ul').toggleClass('active');
            } else {
                $(this).find('ul').removeClass('active');
            }
        });

        $('.gsnav').on('click', function () {
            $('.menu-trigger', this).toggleClass('active');
            $('header ul').toggleClass('active');
            return false;
        });
    }

    faqMenu() {
        $('.template-faq-ttl').on('click', function () {
            $(this).toggleClass('on');
            $(this).next('div').slideToggle();
        });
    }

    pageScroll() {
        $('.page-scroll').on('click', function () {
            const speed = 500;
            const href = $(this).attr("href");
            const target = href == "top" || href == "#" || href == "" ? 'html' : href;
            let position = $(target).offset().top;
            if (target != 'html') {
                position = $(target).offset().top - ($('.header').height() + 30);
                console.log(position);
            }
            $('html, body').animate({scrollTop: position}, speed, 'swing');
            return false;
        });
    }

    input_file() {
        const $radios = $('.mwform-radio-field-text, .mwform-checkbox-field-text');
        if ($radios.length > 0) {
            $radios.each(function () {
                $(this).prepend('<i/>');
            });
        }
    }

    top_slide() {
        $('.visual').slick({
            autoplay: true,
            dots: false,
            arrows: false,
            pauseOnHover: false,
            fade: true,
        });
    }

    init() {
        this.pageScroll();
        this.top_slide();
        this.faqMenu();
        this.gnav();
        this.input_file();
    }
}

let app = new App();

document.addEventListener('DOMContentLoaded', () => {
    app.init();
});

window.addEventListener('load', () => {

});
